import React from "react";
import SEO from "../components/seo";
import {PageIntroSection} from "../components/Partials/PageIntro";
import {Logo} from "../components/Layout/Header";
import {Icon} from "../components/Framework/ui/Icons/Icons";
import {customIcons} from "../components/Framework/ui/Icons/customIcons";
import {evaIconsFill} from "../components/Framework/ui/Icons/iconsEvaFill";
import ParallaxContainer from "../components/Framework/ui/ParallaxContainer/ParallaxContainer";
import {graphql} from "gatsby";
import {Flex} from "../components/Framework/ui/Flex/Flex";

import about from "../images/about-us-cover.jpg";
import teamCover from "../images/our-team.jpg";
import {goToUrl} from "../components/Framework/Utilities";


const About = ({data}) => {
    const team = data.allTeamJson.nodes;
    return (
        <>
            <SEO title="About"/>
            <PageIntroSection label={"About"} bgImage={about}/>
            <section className={"intro text-center"}>
                <Logo className={"mb-xl"} size={50}/>
                <q>When you want something, all the universe conspires in helping you to achieve it.</q>
                <Icon size={"md"} my={"md"} customColor={"#ccc"} icon={customIcons.quotes}/>
                <span>Paulo Coelho</span>
            </section>
            <ParallaxContainer className={"page-header"} image={teamCover} offset={1800} reverseDirection={true}>
                <h2>Our Team</h2>
            </ParallaxContainer>
            <div className={"dark-section"}>
                <div className={"team"}>
                    {team && team.map(member => <TeamMember key={member.id} member={member}/>)}
                </div>
            </div>
        </>
    )
};
export const query = graphql`
{
  allTeamJson {
    nodes {
      id
      name
      position
      icon
      facebook
    }
  }
}
`;
export default About;

const TeamMember = ({member}) => {
    return (
        <Flex className={"team__member"} vertical={true}>
            <div className={"team__member__icon"}>
                <Icon height={180} icon={customIcons[member.icon]}/>
            </div>
            <Flex alignItems={"center"} justifyContent={"between"} className={"team__member__info"}>
                <div>
                    <h3 className={"team__member__info__name"}>{member.name}</h3>
                    <p className={"team__member__info__position"}>{member.position}</p>
                </div>
                <button className={"team__member__facebook"} onClick={() => goToUrl("https://www.facebook.com/" + member.facebook, true)}>
                    <Icon icon={evaIconsFill.facebook}/>
                </button>

            </Flex>
        </Flex>
    )
};
