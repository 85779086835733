import React from "react";
import ParallaxContainer from "../Framework/ui/ParallaxContainer/ParallaxContainer";

export const PageIntroSection = ({bgImage, label}) => {
    return (
        <ParallaxContainer className={"page-header"} image={bgImage} offset={-500} speed={5} reverseDirection={false}>
            <h1>{label}</h1>
        </ParallaxContainer>
    )
};

